import { cloneDeep } from "lodash";
import { firestore } from "utils/firebase";

const getProjectURL = "https://api.d-tools.com/SI/Subscribe/Projects?id=";
const updateProjectURL = "https://api.d-tools.com/SI/Publish/Projects/Update";

export const approveDToolsAdjustment = (
  company,
  historyItem,
  parts,
  manufacturers,
  users,
  alert
) => {
  const {
    id,
    from,
    approved,
    custom,
    partID,
    projectAddition = false,
  } = historyItem;
  const part = parts.find((x) => x.id === partID);
  if (part) {
    if (part.parentRefID) {
      const parent =
        manufacturers && manufacturers.find((x) => x.id === part.parentRefID);
      part.manufacturer = parent ? parent.model : "";
      part.lowercaseMfg = parent ? parent.model.toLowerCase() : "";
    }
    historyItem.manufacturer = part.manufacturer;
    historyItem.model = part.model;
    historyItem.cost = part.cost;
    historyItem.price = part.price;
  } else {
    alert.show(
      "There was an error approving your transaction. Please refresh your browser and try again."
    );
  }
  if (!historyItem.manufacturer) {
    alert.show(
      "There was an error approving your transaction. Please refresh your browser and try again."
    );
  }
  const receivedToLocation = from === "Received To Location";

  //if it's already been synced before, we want to set sync to false
  firestore
    .collection("companies")
    .doc(company.id)
    .collection("history")
    .doc(id)
    .update({
      approved: !approved,
      approvalDate: new Date(),
      synced: true,
    })
    .then(function () {
      // console.log("Document successfully updated!");
      if (!approved) {
        // we're approving so we need to add to queues
        if (!custom && !receivedToLocation && projectAddition) {
          addPartQueue(company, historyItem, users);
        }
      }
    });
};

async function addPartQueue(company, historyItem, users) {
  const { dToolsCustomFields } = company
  //IF IT'S AN INTERNAL INVENTORY MOVE, WE DON'T NEED TO DO ANYTHING IN D-Tools
  if (historyItem.fromClientId || historyItem.toClientId) {
    if (historyItem.toClientId) {
      let deliveredBy = "";
      let deliveredDate = null;
      let deliveredQuantity = historyItem.qty;
      if (historyItem.staged) {
        const poQuerySnapshot = await firestore
          .collection("companies")
          .doc(company.id)
          .collection("purchaseOrders")
          .where("historyDocRef", "==", historyItem.id)
          .get();

        // console.log('poQuerySnapshot', poQuerySnapshot.docs.length, historyItem.id)
        if (poQuerySnapshot.docs.length > 0) {
          const poDoc = poQuerySnapshot.docs[0];
          const poData = poDoc.data();
          // console.log('poData', poData)
          const user = users.find((x) => x.id === poData.deliveredBy);
          if (user) {
            deliveredBy = user.firstName + " " + user.lastName;
            deliveredDate = poData.deliveredDate.toDate();
            deliveredQuantity = poData.deliveredQuantity;
          }
        }
      }

      // const user = users.find((x) => x.id === historyItem.deliveredBy);
      const pickedByUser = users.find((x) => x.id === historyItem.user);
      // const deliveredBy = user.firstName + " " + user.lastName;
      const pickedBy = pickedByUser.firstName + " " + pickedByUser.lastName;

      const updateItems = [];
      for (let i = 0; i < historyItem.qty; i++) {
        const updateItem = {
          Id: historyItem.id + "-" + i,
          TypeId: 1,
          CatalogId: historyItem.partID,
          Manufacturer: historyItem.manufacturer,
          Model: historyItem.model,
          UnitCost: historyItem.cost,
          UnitPrice: historyItem.price,
          Quantity: 1,
          Picked: true,
          PickedDate: historyItem.date.toDate(),
          [dToolsCustomFields.pickedBy]: pickedBy, //picked by
          OrderStatus: "From Stock",
          IsTaxable: true
        };
        if (i + 1 <= deliveredQuantity) {
          updateItem[dToolsCustomFields?.deliveredDate] = !historyItem.staged
            ? historyItem.date.toDate()
            : deliveredDate; //delivered date
          updateItem[dToolsCustomFields?.deliveredBy] = !historyItem.staged
            ? pickedBy
            : deliveredBy; //delivered by
        }
        updateItems.push(updateItem);
      }

      const body = {
        ProjectId: historyItem.toClientId,
        NewProjectItems: updateItems,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "X-DTSI-ApiKey": company.dToolsKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };

      //Part is going TO a client so the client needs billed

      fetch(updateProjectURL, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("result toClientId add part queue", data);
        })
        .catch((e) => {
          console.log("error toClientId add part queue", e);
        });
    }

    if (historyItem.fromClientId) {
      const body = {
        ProjectId: historyItem.fromClientId,
        ProjectItemInfosToDelete: [
          {
            Id: historyItem.id,
            TypeId: 1,
            CatalogId: historyItem.partID,
            Manufacturer: historyItem.manufacturer,
            Model: historyItem.model,
            UnitCost: historyItem.cost,
            UnitPrice: historyItem.price,
            Quantity: historyItem.qty,
          },
        ],
      };

      const data = new FormData();
      for (var key in body) {
        data.append(key, body[key]);
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "X-DTSI-ApiKey": company.dToolsKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };

      //Part is coming FROM a client so the client needs refunded

      fetch(updateProjectURL, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          //   console.log("result fromClientId add part queue", data)
        })
        .catch((e) => {
          console.log("error fromClientId add part queue", e);
        });
    }
  }
}

export function markDToolsItemDelivered(company, selectedItem, deliveredBy) {
  const {
    customerId,
    itemListId,
    quantityToDeliver,
    // deliveredDate, manufacturer, model, cost, price
  } = selectedItem;
  const { dToolsCustomFields } = company
  // const customerId = selectedCustomer.id
  // const itemListId = selectedItem.itemListId
  // console.log('markDToolsItemDelivered',customerId, itemListId, deliveredBy)

  const requestOptions = {
    method: "GET",
    headers: {
      "X-DTSI-ApiKey": company.dToolsKey,
      "Content-Type": "application/json",
    },
  };

  //Part is going TO a client so the client needs billed

  fetch(getProjectURL + customerId, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      // console.log('got d-tools project',data);
      const { Items: items } = data;
      const product = items
        .filter((x) => x.CatalogId === itemListId)
        .map((x) => {
          x.CustomField12 = x.CustomField12 ? x.CustomField12 : "";
          return x;
        });
      if (product.length === 0) return;

      //sort by order status and then by delivered by so the prioritize getting null delivered by first
      const sorted = product.sort(
        (a, b) =>
          a.OrderStatus - b.OrderStatus ||
            a.Picked === b.Picked ? 0 : a.Picked ? -1 : 1 ||
              a.CustomField12.localeCompare(b.CustomField12)
      );
      const updateItems = [];
      // console.log("sorted product1", sorted);
      sorted.forEach((item, index) => {
        if (index + 1 > quantityToDeliver) return;
        let update = {
          SIItemId: item.SIItemId,
          [dToolsCustomFields?.deliveredDate]: new Date(), //delivered date
          [dToolsCustomFields?.deliveredBy]: deliveredBy, //delivered by
        };
        updateItems.push(update);
      });
      //
      const body = {
        ProjectId: customerId,
        UpdateProjectItems: updateItems,
      };
      // console.log('sending body', body)

      const updateRequestOptions = {
        method: "POST",
        headers: {
          "X-DTSI-ApiKey": company.dToolsKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };

      fetch(updateProjectURL, updateRequestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("D-Tools Update Response", data);
        })
        .catch((e) => {
          console.log("error updating item install date", e);
        });
    })
    .catch((e) => {
      console.log("error markDToolsItemInstalled", e);
    });
}

export function markDToolsItemReturned(company, selectedItem, returnedBy) {
  const {
    customerId,
    itemListId,
    quantityToDeliver,
    // deliveredDate, manufacturer, model, cost, price
  } = selectedItem;
  const { dToolsCustomFields } = company
  // const customerId = selectedCustomer.id
  // const itemListId = selectedItem.itemListId
  // console.log('markDToolsItemDelivered',customerId, itemListId, deliveredBy)

  const requestOptions = {
    method: "GET",
    headers: {
      "X-DTSI-ApiKey": company.dToolsKey,
      "Content-Type": "application/json",
    },
  };

  //Part is going TO a client so the client needs billed

  fetch(getProjectURL + customerId, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      // console.log('got d-tools project',data);
      const { Items: items } = data;
      const product = items
        .filter((x) => x.CatalogId === itemListId)
        .map((x) => {
          x.CustomField12 = x.CustomField12 ? x.CustomField12 : "";
          return x;
        });
      if (product.length === 0) return;

      //sort by order status and then by delivered by so the prioritize getting null delivered by first
      const sorted = product.sort(
        (a, b) =>
          a.CustomField14 === b.CustomField14 ? 0 : a.CustomField14 ? 1 : -1
      );
      const updateItems = [];
      // console.log("sorted product1", sorted);
      // return
      sorted.forEach((item, index) => {
        if (index + 1 > quantityToDeliver) return;
        let update = {
          SIItemId: item.SIItemId,
          [dToolsCustomFields?.deliveredDate]: null, //delivered date
          [dToolsCustomFields?.deliveredBy]: '', //delivered by
          [dToolsCustomFields?.returnInfo]: `Returned on ${new Date().toDateString()} by ${returnedBy}`, //returned date
        };
        updateItems.push(update);
      });
      //
      const body = {
        ProjectId: customerId,
        UpdateProjectItems: updateItems,
      };
      // console.log('sending body', body)

      const updateRequestOptions = {
        method: "POST",
        headers: {
          "X-DTSI-ApiKey": company.dToolsKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };

      fetch(updateProjectURL, updateRequestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("D-Tools Update Return Response", data);
        })
        .catch((e) => {
          console.log("error updating item install date", e);
        });
    })
    .catch((e) => {
      console.log("error markDToolsItemInstalled", e);
    });
}

export function markDToolsItemPicked(
  company,
  selectedItem,
  deliveredBy,
  addToStagedItemList
) {
  const {
    customerId,
    itemListId,
    qty,
    // deliveredDate, manufacturer, model, cost, price
  } = selectedItem;
  const { dToolsCustomFields } = company
  // const customerId = selectedCustomer.id
  // const itemListId = selectedItem.itemListId
  // console.log('markDToolsItemDelivered',customerId, itemListId, deliveredBy)

  const requestOptions = {
    method: "GET",
    headers: {
      "X-DTSI-ApiKey": company.dToolsKey,
      "Content-Type": "application/json",
    },
  };

  //Part is going TO a client so the client needs billed

  fetch(getProjectURL + customerId, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      // console.log('got d-tools project',data);
      const { Items: items } = data;
      const product = items.filter((x) => x.CatalogId === itemListId);
      if (product.length === 0) return;

      const sorted = product.sort(
        (a, b) => a.OrderStatus - b.OrderStatus || a.Picked - b.Picked
      );
      // console.log('sorted product',sorted);
      // const item = sorted[sorted?.length - 1];
      const updateItems = [];
      sorted.forEach((item, index) => {
        if (index + 1 > qty) return;
        let update = {
          SIItemId: item.SIItemId,
          Picked: true,
          PickedDate: new Date(),
          [dToolsCustomFields?.pickedBy]: deliveredBy, //picked by
          OrderStatus: "From Stock",
        };
        if (!addToStagedItemList) {
          update = {
            ...update,
            [dToolsCustomFields?.deliveredDate]: new Date(), //delivered date
            [dToolsCustomFields?.deliveredBy]: deliveredBy, //delivered by
          };
        }
        updateItems.push(update);
      });
      const body = {
        ProjectId: customerId,
        UpdateProjectItems: updateItems,
      };
      // console.log('sending body', body)

      const updateRequestOptions = {
        method: "POST",
        headers: {
          "X-DTSI-ApiKey": company.dToolsKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };

      fetch(updateProjectURL, updateRequestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("D-Tools Update Response", data);
        })
        .catch((e) => {
          console.log("error updating item install date", e);
        });
    })
    .catch((e) => {
      console.log("error markDToolsItemInstalled", e);
    });
}

export function fetchDToolsProject(company, customerId, setDToolsProjectItems, setDToolsProjectLocations) {
  const requestOptions = {
    method: "GET",
    headers: {
      "X-DTSI-ApiKey": company.dToolsKey,
      "Content-Type": "application/json",
    },
  };

  //Part is going TO a client so the client needs billed

  fetch(getProjectURL + customerId, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      console.log('got d-tools project', data);
      const { Items: items, Locations: locations } = data;
      setDToolsProjectLocations(locations);
      const product = items && items?.length ? items.filter((x) => x.TypeId === 1) : [];
      //sort by manufacturer then model
      const sorted = product.sort((a, b) =>
        ((a.Manufacturer && a.Manufacturer.localeCompare(b.Manufacturer)) || (a.Model && a.Model.localeCompare(b.Model)))
      );
      setDToolsProjectItems(sorted);
    })
    .catch((e) => {
      console.log("error markDToolsItemInstalled", e);
    });
}

// reduce D-Tools project items and sum quantities
export function reduceDToolsProjectItems(dTools, poParts, stockParts, returnParts, exportReconciliation) {
  console.log(dTools?.length)
  const dToolsProjectItems = cloneDeep(dTools).reduce((acc, cur) => {
    acc.found = true;
    cur.found = true;
    const found = acc.find(x => x.CatalogId === cur.CatalogId && x.CatalogId !== null)
    if (found) {
      found.Quantity += cur.Quantity
    } else {
      acc.push(cur)
    }
    return acc
  }, []);
  console.log('reduced items', dToolsProjectItems?.length)

  // reduce poParts
  const poPartsReduced = cloneDeep(poParts).reduce((acc, cur) => {
    const found = acc.find(x => x.itemListId === cur.itemListId)
    if (found) {
      console.log('found', found.quantity, cur.quantity)
      found.quantity += cur.quantity
      if(isNaN(found.deliveredQuantity)) found.deliveredQuantity = 0
      found.deliveredQuantity += cur?.deliveredQuantity || 0
    } else {
      if(isNaN(cur.deliveredQuantity)) cur.deliveredQuantity = 0
      acc.push(cur)
    }
    return acc
  }, []);
  console.log('reduced poParts', poPartsReduced?.length)

  // reduce stockParts

  const stockPartsReduced = cloneDeep(stockParts).reduce((acc, cur) => {
    const found = acc.find(x => x.partID === cur.partID)
    if (found) {
      found.qty += cur.qty
      if(isNaN(found.deliveredQuantity)) found.deliveredQuantity = 0
      found.deliveredQuantity += cur?.deliveredQuantity || 0
    } else {
      if(isNaN(cur.deliveredQuantity)) cur.deliveredQuantity = 0
      acc.push(cur)
    }
    return acc
  }, []);

  console.log('reduced stockParts', stockPartsReduced?.length)

  // reduce returnParts

  const returnPartsReduced = cloneDeep(returnParts).reduce((acc, cur) => {
    acc.returnedQuantity = acc?.deliveredQuantity || 0
    cur.returnedQuantity = cur?.deliveredQuantity || 0
    const found = acc.find(x => x.partID === cur.partID)
    if (found) {
      if(isNaN(found.returnedQuantity)) found.returnedQuantity = 0
      found.returnedQuantity += cur.returnedQuantity
    } else {
      if(isNaN(cur.returnedQuantity)) cur.returnedQuantity = 0
      acc.push(cur)
    }
    return acc
  }, []);

  console.log('reduced returnParts', returnPartsReduced?.length)

  //add ordered quantity to dToolsProjectItems
  dToolsProjectItems.forEach(item => {
    item.deliveredPO = 0
    item.deliveredStock = 0
    const poPart = poPartsReduced.find(x => x.itemListId === item.CatalogId)
    if (poPart) {
      item.ordered = poPart.quantity
      item.deliveredPO = poPart.deliveredQuantity || 0
    }
    const stockPart = stockPartsReduced.find(x => x.partID === item.CatalogId)
    if (stockPart) {
      item.stock = stockPart.qty
      item.deliveredStock = stockPart.deliveredQuantity || 0
    }
    //sum PO and Stock
    item.delivered = item.deliveredPO + item.deliveredStock
    const returnPart = returnPartsReduced.find(x => x.partID === item.CatalogId)
    if (returnPart) {
      item.returned = returnPart.returnedQuantity
    }
  })

  const poPartsNotFound = poPartsReduced.filter(x => !dToolsProjectItems.find(y => y.CatalogId === x.itemListId))
  const stockPartsNotFound = stockPartsReduced.filter(x => !dToolsProjectItems.find(y => y.CatalogId === x.partID))
  const returnPartsNotFound = returnPartsReduced.filter(x => !dToolsProjectItems.find(y => y.CatalogId === x.partID))
  console.log('poPartsNotFound', poPartsNotFound, 'stockPartsNotFound', stockPartsNotFound, 'returnPartsNotFound', returnPartsNotFound)

  //reduce poPartsNotFound, stockPartsNotFound, and returnPartsNotFound
  const partsNotFound = [];


  //add parts not found in D-Tools
  poPartsNotFound.forEach(item => {
    partsNotFound.push({
      CatalogId: item.itemListId,
      Quantity: 0,
      ordered: item.quantity,
      deliveredPO: item.deliveredQuantity || 0,
      deliveredStock: 0,
      delivered: item.deliveredQuantity || 0,
      returned: 0,
      stock: 0,
      found: false,
      Manufacturer: item.manufacturer,
      Model: item.model,
      itemFullName: item.itemFullName
    })
  })

  stockPartsNotFound.forEach(item => {
    partsNotFound.push({
      CatalogId: item.partID,
      Quantity: 0,
      ordered: 0,
      deliveredPO: 0,
      deliveredStock: item.qty,
      delivered: item.deliveredQuantity || 0,
      returned: 0,
      stock: item.qty,
      found: false,
      Manufacturer: item.manufacturer,
      Model: item.model,
    })
  })

  returnPartsNotFound.forEach(item => {
    partsNotFound.push({
      CatalogId: item.partID,
      Quantity: 0,
      ordered: 0,
      deliveredPO: 0,
      deliveredStock: 0,
      delivered: 0,
      stock: 0,
      returned: item.returnedQuantity,
      found: false,
      Manufacturer: item.manufacturer,
      Model: item.model,
    })
  })


  const reducedPartsNotFound = cloneDeep(partsNotFound).reduce((acc, cur) => {
    const found = acc.find(x => (x.CatalogId === cur.CatalogId) && x.CatalogId !== null)
    if (found) {
      found.Quantity += cur.Quantity
      found.ordered += cur.ordered
      found.deliveredPO += cur.deliveredPO
      found.deliveredStock += cur.deliveredStock
      found.delivered += cur.delivered
      found.stock += cur.stock
      found.returned += cur.returned
      
    } else {
      acc.push(cur)
    }
    return acc
  }, []);

  reducedPartsNotFound.forEach(item => {
    dToolsProjectItems.push(item)
  })

  console.log('added ordered quantity', dToolsProjectItems)

  exportReconciliation(dToolsProjectItems)



}
