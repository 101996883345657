import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import { Links } from "constants/Links";
import { View } from "react-native-web";

import { Inventory } from "screens/Inventory/Inventory";
import { Transactions } from "screens/Transactions/Transactions";
import { Receive } from "screens/Receive/Receive";
import { Projects } from "screens/Reports/Projects/Projects";
import { InventoryByLocation } from "screens/Reports/InventoryByLocation/InventoryByLocation";
import { StagingArea } from "screens/Reports/StagingArea/StagingArea";
import { History } from "screens/Reports/QuickReports/History/History";
import { Dashboard } from "screens/Dashboard/Dashboard";
import { Approvals } from "screens/Approvals/Approvals";
import { SerialNumbers } from "screens/SerialNumbers/SerialNumbers";
import { MinMaxReport } from "screens/Dashboard/MinMaxReport";
import { DeliveredItems } from "screens/Reports/QuickReports/DeliveredItems";
import { Settings } from "screens/Company/Settings/Settings";
import { InventoryLocations } from "screens/Company/InventoryLocations";
import { Users } from "screens/Company/Users";
import { Valuation } from "screens/Company/Valuation/Valuation";
import { useDisplay } from "context/DisplayContext";
import { StagingAreaTransaction } from "screens/Transactions/StagingAreaTransaction";
import { QuickReports } from "screens/Reports/QuickReports/QuickReports";
import { Audits } from "screens/Audits/Audits";
import { AuditReport } from "screens/Audits/AuditReport";
import { AgingItems } from "screens/Reports/QuickReports/AgingItems";
import { MostValuableItems } from "screens/Reports/QuickReports/MostValuableItems";
import { ValueByLocation } from "screens/Reports/QuickReports/ValueByLocation";
import { Expired } from "screens/Expired/Expired";
import { useCompany } from "context/CompanyContext";
import { Onboarding } from "screens/Onboarding/Onboarding";
import { QuickBooks } from "screens/QuickBooks/QuickBooks";
import { Support } from "screens/Support/Support";
import { DTools } from "screens/DTools/DTools";
import { BStockItems } from "screens/Reports/QuickReports/BStockItems";
import { OpenPurchaseOrders } from "screens/Reports/QuickReports/OpenPurchaseOrders";
import { ReturnedItems } from "screens/Reports/QuickReports/ReturnedItems";
import { DToolsOverview } from "screens/Support/DToolsOverview";
import { DToolsInventoryLocations } from "screens/Support/DToolsInventoryLocations";
import { PurchaseOrders } from "screens/Reports/QuickReports/PurchaseOrders";


export default function MainNavigator() {

  const {showSidebar, setShowSidebar} = useDisplay()
  const {company, upcomingInvoice, paymentInfo} = useCompany()

  const inTrial = company.trialEndDate ? (company.trialEndDate.toDate().getTime() > new Date().getTime()) : false
  
  let active = company?.active || inTrial
  // active = false //testing
  let needOnboarding = (company?.qbo===undefined && !company.type)

  let quickbooksSync = true
  if (company.qbo===true && !company?.qboRealmId) quickbooksSync = false //qbo
  if (company.qbo===false && !company?.quickbooksSync) quickbooksSync = false //qbDesktop

  let dToolsKey = true
  if (company?.type === 'dtoolsSI' && !company?.dToolsKey) dToolsKey = false //d-tools SI


  // console.log('upcomingInvoice', upcomingInvoice?.status)

  if (upcomingInvoice?.status==="Past Due" || paymentInfo?.expired===true) active = false


  return (
    <View style={{flex: 1, marginLeft: showSidebar ? 280 : 60, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 24, minWidth: 1024, paddingTop: 12}}>
      {active && !needOnboarding ?
      <Routes>
        
        <Route path={Links.Dashboard} element={<Dashboard/>} />

        {/** redirects */}
        {quickbooksSync && dToolsKey ?
          <Route path={'/'} element={<Navigate replace to={Links.Dashboard} />} />
          :
          <>
          {!quickbooksSync ?
            <Route path={'/'} element={<Navigate replace to={Links.QuickBooks} />} />
          :
            <Route path={'/'} element={<Navigate replace to={Links.DTools} />} />
          }
          </>
        }

        <Route path={'/expired'} element={<Navigate replace to={Links.Dashboard} />} />

        {company.qbo===undefined ? 
          <Route path={'/onboarding'} element={<Navigate replace to={Links.DTools} />} />
        :
          <>
          {company.qbo===undefined ? 
            <Route path={'/onboarding'} element={<Navigate replace to={Links.Dashboard} />} />
          :
            <Route path={'/onboarding'} element={<Navigate replace to={Links.QuickBooks} />} />
          }
          </>
        }
        
        {/** end redirects */}

        <Route path={Links.Inventory} element={<Inventory/>} />
        <Route path={Links.Transactions} element={<Transactions/>} />
        <Route path={Links.Receive} element={<Receive/>} />
        <Route path={Links.StagingAreaTransaction} element={<StagingAreaTransaction/>} />
        <Route path={Links.Approvals} element={<Approvals/>} />
        <Route path={Links.Serial} element={<SerialNumbers/>} />

        {/** reports */}
        <Route path={Links.Projects} element={<Projects/>} />
        <Route path={Links.InventoryByLocation} element={<InventoryByLocation/>} />
        <Route path={Links.StagingArea} element={<StagingArea/>} />
        <Route path={Links.History} element={<History/>} />
        <Route path={Links.Audits} element={<Audits/>} />
        <Route path={Links.AuditReport} element={<AuditReport/>} />
        <Route path={Links.Quick} element={<QuickReports/>} />
        <Route path={Links.MinMax} element={<MinMaxReport/>} />
        <Route path={Links.Delivered} element={<DeliveredItems/>} />
        <Route path={Links.Returned} element={<ReturnedItems/>} />
        <Route path={Links.AgingItems} element={<AgingItems/>} />
        <Route path={Links.MostValuableItems} element={<MostValuableItems/>} />
        <Route path={Links.ValueByLocation} element={<ValueByLocation/>} />
        <Route path={Links.BStock} element={<BStockItems/>} />
        {/* <Route path={Links.OpenPurchaseOrders} element={<OpenPurchaseOrders/>} /> */}
        <Route path={Links.OpenPurchaseOrders} element={<PurchaseOrders/>} />

        {/** company */}
        <Route path={Links.Settings} element={<Settings/>} />
        <Route path={Links.InventoryLocations} element={<InventoryLocations/>} />
        <Route path={Links.Users} element={<Users/>} />
        <Route path={Links.TotalValuation} element={<Valuation/>} />
        <Route path={Links.QuickBooks} element={<QuickBooks/>} />
        <Route path={Links.DTools} element={<DTools/>} />

        <Route path={Links.Support} element={<Support/>} />
        <Route path={Links.DToolsOverview} element={<DToolsOverview/>} />
        <Route path={Links.DToolsInventoryLocations} element={<DToolsInventoryLocations/>} />

      </Routes>
    : 
      <>
        {needOnboarding ?
            <Routes>
              <Route path={Links.Onboarding} element={<Onboarding/>} />
              <Route path={'/*'} element={<Navigate replace to={Links.Onboarding} />} />
            </Routes>
            :
            <Routes>
              <Route path={Links.Expired} element={<Expired/>} />
              <Route path={'/*'} element={<Navigate replace to={Links.Expired} />} />
            </Routes>
        }
      </>
      }
      
    </View>
  )
}
